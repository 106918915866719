import React from 'react'
import { Link } from 'gatsby'

import './style.css'

const getRandomStr = (len = 15) => {
	let text = ''
	let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
	for (let i = 0; i < len; i++) {
		text += possible.charAt(Math.floor(Math.random() * possible.length))
	}
	return text
}

const Paginator = ({ index, pageCount, relativeUrl }) => {
	let result = []

	if (pageCount >= 11) {
		if (index <= 2) {
			result.push(<Link key={getRandomStr()} to={relativeUrl}>{'<'}</Link>)
		} else {
			result.push(<Link key={getRandomStr()} to={`${relativeUrl}/${index - 1}`}>{'<'}</Link>)
		}
	}

	if (pageCount < 11) {
		Object.keys(Array.from({ length: pageCount })).forEach((item, listIndex) => {
			result.push(
				<Link
					key={getRandomStr()}
					className={listIndex + 1 === index ? 'currentPage' : ''}
					to={`${relativeUrl}/${listIndex === 0 ? '' : listIndex + 1}`}
				>{listIndex + 1}</Link>
			)
		})
	} else {
		if (index <= 5) {
			// 低数 index + 2   高位两个
			Object.keys(Array.from({ length: index + 2 })).forEach((item, listIndex) => {
				result.push(
					<Link
						key={getRandomStr()}
						className={listIndex + 1 === index ? 'currentPage' : ''}
						to={`${relativeUrl}/${listIndex === 0 ? '' : listIndex + 1}`}
					>{listIndex + 1}</Link>
				)
			})
			result.push(<span key={getRandomStr()}>...</span>)
			result.push(<Link key={getRandomStr()} to={`${relativeUrl}/${pageCount - 2}`}>{pageCount - 2}</Link>)
			result.push(<Link key={getRandomStr()} to={`${relativeUrl}/${pageCount - 1}`}>{pageCount - 1}</Link>)
			result.push(<Link key={getRandomStr()} to={`${relativeUrl}/${pageCount}`}>{pageCount}</Link>)
		} else if (index >= pageCount - 4) {
			// 低位两个   高位 index - 2 到顶
			result.push(<Link key={getRandomStr()} to={`${relativeUrl}`}>1</Link>)
			result.push(<Link key={getRandomStr()} to={`${relativeUrl}/2`}>2</Link>)
			result.push(<Link key={getRandomStr()} to={`${relativeUrl}/3`}>3</Link>)
			result.push(<span key={getRandomStr()}>...</span>)
			// pageCount - (index - 2) + 1 = pageCount - index + 3
			Object.keys(Array.from({ length: pageCount - index + 3  })).forEach((item, listIndex) => {
				let newIndex = listIndex + index - 3
				result.push(
					<Link
						key={getRandomStr()}
						className={newIndex + 1 === index ? 'currentPage' : ''}
						to={`${relativeUrl}/${newIndex === 0 ? '' : newIndex + 1}`}
					>{newIndex + 1}</Link>
				)
			})
		} else {
			// 低位两个  中间 index - 2 ~ index + 2  高位两个
			result.push(<Link key={getRandomStr()} to={`${relativeUrl}`}>1</Link>)
			result.push(<Link key={getRandomStr()} to={`${relativeUrl}/2`}>2</Link>)
			result.push(<span key={getRandomStr()}>...</span>)
			result.push(<Link key={getRandomStr()} to={`${relativeUrl}/${index - 2}`}>{index - 2}</Link>)
			result.push(<Link key={getRandomStr()} to={`${relativeUrl}/${index - 1}`}>{index - 1}</Link>)
			result.push(<Link key={getRandomStr()} className="currentPage" to={`${relativeUrl}/${index}`}>{index}</Link>)
			result.push(<Link key={getRandomStr()} to={`${relativeUrl}/${index + 1}`}>{index + 1}</Link>)
			result.push(<Link key={getRandomStr()} to={`${relativeUrl}/${index + 2}`}>{index + 2}</Link>)
			result.push(<span key={getRandomStr()}>...</span>)
			result.push(<Link key={getRandomStr()} to={`${relativeUrl}/${pageCount - 1}`}>{pageCount - 1}</Link>)
			result.push(<Link key={getRandomStr()} to={`${relativeUrl}/${pageCount}`}>{pageCount}</Link>)
		}
	}

	if (pageCount >= 11) {
		if (index === pageCount) {
			result.push(<Link key={getRandomStr()} to={`${relativeUrl}/${pageCount}`}>{'>'}</Link>)
		} else {
			result.push(<Link key={getRandomStr()} to={`${relativeUrl}/${index + 1}`}>{'>'}</Link>)
		}
	}

	return <div className="paginator">
		{result}
	</div>
}

export default Paginator
