import React from 'react'
import { Link } from 'gatsby'

import Paginator from '@/components/Paginator'

import './style.css'

const { Fragment } = React

const NormalHomePage = ({ pageContext }) => {
	const {
		group,
		index,
		pageCount
	} = pageContext

	return <Fragment>
		<Paginator index={index} pageCount={pageCount} relativeUrl="" />
		{group.map(({ node }) => (
			<div className="normal-homepage-item" key={node.id}>
				<h3><Link className="title" to={node.frontmatter.path}>{node.frontmatter.title}</Link></h3>
				<h6 className="information">Posted by {node.frontmatter.author} on {node.frontmatter.date}</h6>
				<hr className="segmenter" />
				<div dangerouslySetInnerHTML={{__html: node.html.split('<!--more-->')[0]}}></div>
				<Link to={node.frontmatter.path}>Read More</Link>
				<br/>
			</div>
		))}
		<Paginator index={index} pageCount={pageCount} relativeUrl="" />
	</Fragment>
}

export default NormalHomePage
