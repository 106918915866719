import React from 'react'
import PropTypes from 'prop-types'

import NormalHomePage from './normal'
import HolidayHomePage from './holiday'

const HomeComponentMapping = {
	normal  : NormalHomePage,
	holiday : HolidayHomePage
}

const HomePage = ({ type, pageContext }) => {
	const TheHomePage = HomeComponentMapping[type]

	return <TheHomePage pageContext={pageContext} />
}

HomePage.propTypes = {
	type: PropTypes.string
}

HomePage.defaultProps = {
	type: 'normal'
}

export default HomePage
