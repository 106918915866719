import React from 'react'

import Layout from '../components/layout'
import HomePage from '../components/Home'
import SEO from '../components/seo'

const Template = ({ pageContext }) => (
  <Layout>
    <SEO title="Boliball" />
    <HomePage type="normal" pageContext={pageContext} />
  </Layout>
)

export default Template
